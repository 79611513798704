// firebase.js
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCPEjohT4yyZII_gMhpoyCGe5booieBvNM",
  authDomain: "durak360-b6f73.firebaseapp.com",
  databaseURL: "https://durak360-b6f73-default-rtdb.firebaseio.com",
  projectId: "durak360-b6f73",
  storageBucket: "durak360-b6f73.firebasestorage.app",
  messagingSenderId: "1034453770585",
  appId: "1:1034453770585:web:4222c2da420841305d2c48",
  measurementId: "G-VFTGB33CYL",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };
