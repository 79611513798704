import { ref, get } from "firebase/database";
import { database } from "./firebase";

export const fetchMetroLines = async () => {
  try {
    const cachedData = localStorage.getItem("metroLines");
    if (cachedData) {
      console.log("Cached Data:", JSON.parse(cachedData));
      return JSON.parse(cachedData);
    } else {
      const snapshot = await get(ref(database, "metroLines"));
      if (snapshot.exists()) {
        const data = snapshot.val();
        localStorage.setItem("metroLines", JSON.stringify(data));
        return data;
      } else {
        console.log("No data found!");
      }
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
